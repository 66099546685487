import styled from "styled-components";

export const HeadlineXL = styled.h1`
  margin: 0;
  display: block;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  line-height: 90px;
  text-transform: uppercase;
  color: #1F1F1F;
  height: 90px;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  :first-child {
    margin-right: 20px;

  }
  :nth-child(2) {

  }
  :nth-child(3) {
    animation-delay: .2s;
    z-index: 2;
  }
  :last-child {
    animation-delay: .4s;
    z-index: 3;
  }
  @media screen and (max-width: 1400px) {
    font-size: 70px;
    line-height: 80px;
    height: 80px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 60px;
    line-height: 70px;
    height: 70px;
  }
  @media screen and (max-width: 768px) {
    font-size: 50px;
    line-height: 60px;
    height: 60px;
  }
  @media screen and (max-width: 480px) {
    font-size: 35px;
    line-height: 45px;
    height: 45px;
    :first-child {
      width: 100%;

    }
    :nth-child(2) {
      width: 100%;
      animation-delay: .2s;
      z-index: 2;
    }
    :nth-child(3) {
      animation-delay: .4s;
      z-index: 3;
    }
    :last-child {
      animation-delay: .5s;
      z-index: 4;
    }
  }
  @media screen and (max-width: 320px) {
    font-size: 30px;
    line-height: 40px;
    height: 40px;
  }
`
export const HeadlineL = styled.h2`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 72px;
  line-height: 82px;
  text-transform: uppercase;
  opacity: 0;
  color: #1F1F1F;
  :first-child {
    margin-right: 20px;
  }
  
  @media screen and (max-width: 1400px) {
    font-size: 62px;
    line-height: 72px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 50px;
    line-height: 60px;
  }
  @media screen and (max-width: 768px) {
    font-size: 40px;
    line-height: 50px;
    :first-child {
      width: 100%;
    }
    :last-child {
      animation-delay: .2s;
      z-index: 2;
    }
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
    line-height: 38px;
  }
`
export const HeadlineL2 = styled(HeadlineL)`
  :first-child {
    width: 100%;
  }
  :last-child {
    animation-delay: .2s;
    z-index: 2;
  }
`
export const SubheaderXL = styled.h4`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 32px;
  color: #1F1F1F;
  @media screen and (max-width: 480px) {
    font-size: 24px;
    line-height: 24px;
  }
`
export const SubheaderL = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const BodyXL = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  color: #1F1F1F;
  @media screen and (max-width: 1400px) {
    font-size: 20px;
    line-height: 32px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }
`
export const BodyXLSecondary = styled(BodyXL)`
  color: #787878;
`
export const BodyXLMedium = styled(BodyXL)`
  font-weight: 500;
`
export const BodyXLMediumSecondary = styled(BodyXLMedium)`
  color: #787878;
`

export const BodyL = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #1F1F1F;
  @media screen and (max-width: 1400px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    line-height: 22px;
  }
`
export const BodyLSecondary = styled(BodyL)`
  color: #787878;
`
export const BodyM = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const BodyS = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 15px;
    line-height: 22px;
  }
`
export const BodySSecondary = styled(BodyS)`
  color: #787878;
`
export const LinkXL = styled.a`
  text-decoration: none;
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #1F1F1F;
  cursor: pointer;
  user-select: none;
  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  @media screen and (max-width: 1400px) {
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const LinkLRegular = styled.a`
  text-decoration: none;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #1F1F1F;
  cursor: pointer;

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const LinkLMedium = styled(LinkLRegular)`

  font-weight: 500;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const LinkLDemibold = styled(LinkLRegular)`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const LinkMRegular = styled.a`
  text-decoration: none;
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  cursor: pointer;

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  @media screen and (max-width: 1024px) {

  }
  @media screen and (max-width: 768px) {

  }
`
export const LinkMDemibold = styled(LinkMRegular)`
  font-weight: 500;
  @media screen and (max-width: 1024px) {

  }
  @media screen and (max-width: 768px) {

  }
`
export const LinkMUpperCase = styled.a`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #1F1F1F;
  cursor: pointer;

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }
`
export const BtnLabelXL = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 768px) {

  }
`
export const BtnLabelL = styled.div`
  margin: 0;
  font-family: Golos;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {

  }
  @media screen and (max-width: 768px) {

  }
`
export const Caption = styled.div`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  color: #1F1F1F;
  @media screen and (max-width: 1024px) {

  }
  @media screen and (max-width: 768px) {

  }
`
export const CaptionSecondary = styled(Caption)`
  color: #787878;
  opacity: 0.75;
`