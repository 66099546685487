import {IText} from "../../interface";

export const BusinessText : IText = {
    title1 : "Преимущества",
    title2 : "для бизнеса",
    dateTimeTitle : "Экономия времени и ресурсов",
    dateTimeText : "Оцифровка всех бизнес-процессов позволит выявить скрытые резервы времени и ресурсов",
    delayTitle : "Снижение ошибок и задержек",
    delayText : "Алгоритмы машинного обучения и анализ по сгенерированным задачам позволят уменьшить количество ошибок связанных с человеческим фактором",
    controlTitle : "Оптимизация и контроль",
    controlText : "Единая система управления позволит оптимизировать стандарты и бизнес-процессы, а также контролировать их соблюдение",
    moneyTitle : "Экономия денежных средств",
    moneyText : "Высокий уровень краткосрочного и долгосрочного планирования сократит ваши эксплутационные расходы",
    supervisorTitle : "Контроль работы сотрудников",
    supervisorText : "Вы будете знать, почему происходят задержки работ, и как используется рабочее оборудование. Это позволит оперативно принимать бизнес-решения",
    supportTitle : "Круглосуточная поддержка",
    supportText : "Оперативно решим возникающие у вас проблемы, а также проведем консультации по работе с системой",
    interfaceTitle : "Легкая масштабируемость",
    interfaceText : "Внедряем только те модули, которые нужны на данный момент, и при необходимости модифицируем их под ваши задачи"
}