import {BodyLSecondary, HeadlineXL, LinkXL} from "../../styled-components/Font";
import {MainContentText} from "./text";
import {MainImageContainer, MainRow, MainSection, Maintext} from "./Styled";
import {Title} from "../../styled-components/Base";
import React, {useEffect, useRef, useState} from "react";
import useOnScreen from "../../hooks/useOnScreen";
import {
    Popup,
    PopupBody,
    PopupContent,
    PopupForm,
    PopupHeader,
    PopupInputContainer,
    PopupSubtitle,
    PopupTitle
} from "../../styled-components/Popup";
import useOnclickOutside from "react-cool-onclickoutside";
import {Close} from "../../icons/Icons";
import {DisabledPrimaryButtonXl, PrimaryButtonXl} from "../../styled-components/Button";
import {FormInput, FormLabel} from "../../styled-components/Form";
import {PopupProps} from "../../App";
import {sendForm} from "../../utils/sendForm";
import NumberFormat from "react-number-format";

export default function MainContent({isPopupOpened, onPopupOpen, onPopupClose, popupText, setPopupText}: PopupProps) {

    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    const [isResponsePopupOpened, setIsResponsePopupOpened] = useState(false)
    const popupRef = useOnclickOutside(() => {
        onPopupClose && !popupText?.toLowerCase().includes('ошибка') && onPopupClose();
    });
    const thanksPopupRef = useOnclickOutside(() => {
        setIsResponsePopupOpened(false);
        setPopupText && setPopupText('')
    });
    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])
    useEffect(() => {
        if (popupText) {
            setIsResponsePopupOpened(true)
        } else setIsResponsePopupOpened(false)
    }, [popupText])


    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)

    const [emailError, setEmailError] = useState('')
    const [nameError, setNameError] = useState('')
    const [phoneError, setPhoneError] = useState('')

    const [emailErrorVisible, setEmailErrorVisible] = useState(false)
    const [nameErrorVisible, setNameErrorVisible] = useState(false)
    const [phoneErrorVisible, setPhoneErrorVisible] = useState(false)


    const labelStyle = {
        fontSize: '10px',
        transform: 'translateY(-20px)'
    }

    return <MainSection ref={ref}>
        <MainRow>
            <Title>

                <HeadlineXL className={isVisible ? 'animation1' : ''}>
                    {MainContentText.title}
                </HeadlineXL>

                <HeadlineXL className={isVisible ? 'animation1' : ''}>
                    {MainContentText.title1}
                </HeadlineXL>

                <HeadlineXL className={isVisible ? 'animation1' : ''}>
                    {MainContentText.title2}
                </HeadlineXL>

                <HeadlineXL className={isVisible ? 'animation1' : ''}>
                    {MainContentText.title3}
                </HeadlineXL>


            </Title>


            <Maintext className={isVisible ? "animation2" : ''}>
                <BodyLSecondary>
                    {MainContentText.text}
                </BodyLSecondary>
                <LinkXL onClick={() => onPopupOpen()}>
                    Скачать нашу презентацию
                </LinkXL>
            </Maintext>
        </MainRow>
        <MainImageContainer>
            <img className={isVisible ? "animation5" : ''} src={'images/Rectangle1691.png'} alt={''}/>
        </MainImageContainer>
        <Popup className={isPopupOpened ? 'visible' : 'hidden'}>
            <PopupContent ref={popupRef}>
                <PopupHeader>
                    <PopupTitle>Заявка</PopupTitle>
                    <Close onClick={() => onPopupClose && onPopupClose()}/>
                </PopupHeader>
                <PopupBody>
                    <PopupSubtitle>Заполните заявку и мы вышлем презентацию
                        на ваш e-mail</PopupSubtitle>

                    <PopupForm className={'form'} noValidate>

                        <PopupInputContainer>
                            <FormInput
                                className={nameErrorVisible && nameError ? 'underline-error' : 'underline'}
                                onFocus={() => setNameErrorVisible(false)}
                                onBlur={() => setNameErrorVisible(true)}
                                onChange={e => {
                                    setName(e.currentTarget.value)
                                    !e.currentTarget.validity.valid
                                        ? e.currentTarget.validationMessage.includes('указанном')
                                            ? setNameError('Поле может содержать только буквы.')
                                            : e.currentTarget.validationMessage.includes('символ')
                                                ? setNameError('Введите минимум 2 символа.')
                                                : setNameError(e.currentTarget.validationMessage)
                                        : setNameError('')
                                }}
                                required
                                pattern={'[a-zA-Z\u0400-\u04ff-\\s]+'}
                                maxLength={150}
                                minLength={2}
                                value={name}
                                id={'name'}/>
                            <FormLabel
                                style={name.length > 0 ? labelStyle : {}}
                                htmlFor="name">
                                Ваше Имя
                            </FormLabel>
                            <span style={nameErrorVisible ? {display: "block"} : {}}>{nameError}</span>
                        </PopupInputContainer>
                        <PopupInputContainer>
                            <FormInput
                                className={emailErrorVisible && emailError ? 'underline-error' : 'underline'}
                                onFocus={() => setEmailErrorVisible(false)}
                                onBlur={() => setEmailErrorVisible(true)}
                                onChange={e => {
                                    setEmail(e.currentTarget.value)
                                    !e.currentTarget.validity.valid
                                        ? setEmailError(`Введите корректный e-mail.`)
                                        : setEmailError('')
                                }}
                                value={email}
                                required
                                maxLength={100}
                                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                id={'email'}
                                type={'email'}/>
                            <FormLabel
                                style={email.length > 0 ? labelStyle : {}}
                                htmlFor="email">
                                E-mail
                            </FormLabel>
                            <span style={emailErrorVisible ? {display: "block"} : {}}>{emailError}</span>
                        </PopupInputContainer>
                        <PopupInputContainer>
                            <NumberFormat
                                className={`${phoneErrorVisible && phoneError ? 'underline-error' : 'underline'} numberInput`}
                                id={'phone'}
                                type={'tel'}
                                format={'+#(###)###-##-##'}
                                mask={'_'}
                                onFocus={() => setPhoneErrorVisible(false)}
                                onBlur={() => setPhoneErrorVisible(true)}
                                onValueChange={(value) => {
                                    let inputValue
                                    if (value.value.substring(0,1) === '8') {
                                        inputValue = '+7' + value.formattedValue.substring(2, value.formattedValue.length)
                                    } else inputValue = value.formattedValue

                                    if (inputValue.includes('_'))  {
                                        setPhoneError('Введите корректный номер телефона.')
                                    } else { setPhoneError('')
                                    }
                                    if (inputValue.length === 0)  {
                                        setPhoneError('Введите номер телефона.')
                                    }
                                    setPhone(inputValue)
                                }}
                                value={phone}
                                title={'Введите номер телефона'}
                                required
                            />
                            <FormLabel
                                style={phone.length > 0 ? labelStyle : {}}
                                htmlFor="phone">
                                Номер телефона
                            </FormLabel>
                            <span style={phoneErrorVisible ? {display: "block"} : {}}>{phoneError}</span>
                        </PopupInputContainer>


                        <PrimaryButtonXl
                            onClick={(e: React.MouseEvent) => {
                                e.preventDefault()
                                setLoading(true)
                                sendForm({
                                    form: {
                                        name: name,
                                        phone: phone,
                                        type: "Запрос презентации",
                                        email: email
                                    }
                                }).then((res) => {
                                    if (res.ok) {
                                        onPopupClose && onPopupClose()
                                        setPopupText && setPopupText('Ваша заявка отправлена, мы вышлем презентацию вам на почту')
                                        setName('')
                                        setPhone('')
                                        setEmail('')
                                        setEmailErrorVisible(false)
                                        setNameErrorVisible(false)
                                        setPhoneErrorVisible(false)
                                    }
                                }).catch(() => {
                                    setPopupText && setPopupText('Произошла ошибка, попробуйте еще раз.')
                                }).finally(() => setLoading(false))
                            }
                            }
                            as={
                                nameError
                                || emailError
                                || phoneError
                                || name === ''
                                || email === ''
                                || phone === ''
                                || loading
                                    ? DisabledPrimaryButtonXl
                                    : PrimaryButtonXl}>
                            {loading ? 'Отправка...' : 'Отправить заявку'}
                        </PrimaryButtonXl>
                    </PopupForm>
                </PopupBody>
            </PopupContent>
        </Popup>

        <Popup className={isResponsePopupOpened ? 'visible' : 'hidden'}>
            <PopupContent ref={thanksPopupRef}>
                <PopupHeader>
                    <PopupTitle>{popupText ? popupText.toLowerCase().includes('ошибка') ? 'Ошибка' : 'Спасибо!' : ''}</PopupTitle>
                    <Close onClick={() => {
                        setIsResponsePopupOpened(false)
                        setPopupText && setPopupText('')
                    }}/>
                </PopupHeader>
                <PopupBody>
                    <PopupSubtitle>
                        {isResponsePopupOpened && popupText}
                    </PopupSubtitle>


                </PopupBody>
            </PopupContent>
        </Popup>
    </MainSection>
}
