import {IText} from "../../interface";

export const ContactText : IText = {
    title : "Связаться ",
    title1 : "с нами",
    addressTitle : 'Адрес',
    address : 'Волкова 31, пом. 201, Казань',
    contact : 'Контакты',
    phone : '+7 (843) 248-55-54',
    email : 'projects@deck.lc'
}