export const Logo = () => <svg width="112" height="29" viewBox="0 0 112 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 27.3511V0.642578H6.53912C8.72967 0.642578 11.3648 0.715891 13.3168 1.8889C15.6483 3.31005 16.0225 5.91549 16.0225 8.47581V20.3356C15.9899 23.0087 15.1983 24.9656 13.4524 26.1048C11.8799 27.1707 9.9605 27.3511 8.11154 27.3511H0ZM6.02943 22.5463C10.0689 22.8283 10.1069 21.1252 9.99845 17.5554V8.8311C9.96592 5.69555 8.97366 5.52072 6.02943 5.44741V22.5463Z" fill="#1F1F1F"/>
    <path d="M17.9092 27.3511V0.642578H31.7086V5.98316H23.9332V10.8951H31.47V16.2357H23.9332V22.0106H32.1152V27.3511H17.9092Z" fill="#1F1F1F"/>
    <path d="M43.9303 9.97623V8.26747C43.9303 5.98912 43.1441 4.81611 42.0109 4.81611C40.1294 4.78228 39.6793 5.84814 39.6793 7.59637L39.6468 20.8097C39.6468 22.3041 40.2324 23.1952 41.8048 23.1952C43.8598 23.1952 44.0279 20.9845 44.0279 19.3547V16.9297H50.0573V20.2062C50.0573 24.7968 47.5252 28.0056 41.3548 28.0056C35.8079 28.0056 33.6499 24.9434 33.6499 19.4957V8.51561C33.6553 2.70695 36.2254 0 41.7398 0C46.88 0 49.9597 2.27835 49.9597 7.72608V9.97059L43.9303 9.97623Z" fill="#1F1F1F"/>
    <path d="M51.7383 27.3511V0.642578H57.7677V11.3237H57.8382L62.2844 0.642578H68.4494L62.973 12.4347L68.791 27.3511H62.4253L59.1016 18.0573L57.7623 20.7642V27.3511H51.7383Z" fill="#1F1F1F"/>
    <path d="M74.3431 0.648438H81.9341L86.5972 27.357H80.6003L80.0689 22.6086H76.0619L75.4709 27.357H69.7451L74.3431 0.648438ZM76.8807 17.8714H79.8303L78.4477 4.79345H78.3826L76.8807 17.8714Z" fill="#59B1FF"/>
    <path d="M88.2837 0.648438H94.183V27.3514H88.2837V0.648438Z" fill="#59B1FF"/>
    <path d="M96.254 0.649089H104.062C109.11 0.508102 111.604 2.65674 111.544 7.09501C111.566 8.65151 111.371 9.94859 110.959 10.9806C110.487 12.0014 109.489 12.7683 107.96 13.2815V13.3492C110.189 13.8624 111.338 15.2779 111.414 17.5957V22.316C111.414 23.2296 111.436 24.177 111.474 25.1583C111.534 26.1395 111.707 26.8727 111.994 27.3633H106.182C105.878 26.8727 105.689 26.1395 105.602 25.1583C105.52 24.177 105.488 23.2296 105.51 22.316V18.0356C105.51 17.2517 105.347 16.6821 105.022 16.3212C104.675 15.9828 104.078 15.8136 103.238 15.8136H102.148V27.352H96.2485V0.649089H96.254ZM102.159 11.2739H103.612C104.604 11.2739 105.206 10.9637 105.428 10.3433C105.596 9.75684 105.672 9.01243 105.651 8.12139C105.651 7.65332 105.64 7.236 105.618 6.86379C105.596 6.48031 105.515 6.17578 105.363 5.9502C105.228 5.69078 104.994 5.4934 104.669 5.36369C104.355 5.24526 103.899 5.18323 103.314 5.18323H102.164V11.2739H102.159Z" fill="#59B1FF"/>
</svg>

export const Close = (props: {onClick: () => void }) => <svg onClick={props.onClick} cursor={'pointer'} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.44362 28.8844L28.7059 1.62183L1.44362 28.8844ZM1.44362 1.62183L28.7059 28.8844L1.44362 1.62183Z" stroke="#989898" strokeOpacity="0.75" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

export const Clear = (props: {onClick: () => void }) => <svg onClick={props.onClick} cursor={'pointer'} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 10L10 2L2 10ZM2 2L10 10L2 2Z" stroke="#1F1F1F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
</svg>