import React, {useState} from 'react';
import {View} from "./styled-components/View";
import Header from "./components/Header";
import MainContent from "./components/MainContent/MainContent";
import AboutProduct from './components/AboutProduct/AboutProduct';
import Clients from "./components/Clients/Clients";
import Business from "./components/Business/Business";
import Function from "./components/Function/Function";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer";

export interface PopupProps {
    isPopupOpened?: boolean
    onPopupOpen: () => void
    onPopupClose?: () => void
    setPopupText?: (value: string) => void
    popupText?: string
}

function App() {

    const [popupOpened, setPopupOpened] = useState(false)
    const [popupText, setPopupText] = useState('')

    return <View>
        <Header/>
        <MainContent
            isPopupOpened={popupOpened}
            onPopupOpen={() => setPopupOpened(true)}
            onPopupClose={() => {
                setPopupText('')
                setPopupOpened(false)
            }}
            setPopupText={(value) => setPopupText(value)}
            popupText={popupText}/>
        <AboutProduct/>
        <Clients/>
        <Business/>
        <Function/>
        <Contact setPopupText={(value) => setPopupText(value)}
            onPopupOpen={() => {
                setPopupOpened(true)
            }}/>
        <Footer/>
    </View>
}

export default App;
