import styled from "styled-components";


export const Form = styled.form`
  display: flex;
  width: 100%;
`

export const FormInput = styled.input`
  border: none;
  position: absolute;
  width: 100%;
  font-family: Golos, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1F1F1F;
  outline: none;
  z-index: 2;
  background: transparent;
  height: 40px;

  :focus + label {
    font-size: 10px;
    transform: translateY(-20px);
    
  }
`
export const FormLabel = styled.label`
  user-select: none;
  position: absolute;
  display: flex;
  color: #787878;
  z-index: 1;
  //transform: translateY(-40px);
  font-size: 16px;
  line-height: 24px;
  transition: 0.5s;
  @media screen and (max-width: 1024px) {
    font-size: 16px;
    line-height: 24px;
  }
`
