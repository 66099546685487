import styled from "styled-components";

export const Section = styled.div`
  padding: 60px 0 200px;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 60px;
  @media screen and (max-width: 1024px) {
    padding: 44px 0 120px;
    gap: 40px;
  }
  @media screen and (max-width: 768px) {
    padding: 30px 0 60px;
    gap: 30px;
  }
`
export const Content = styled.div`
  display: flex;
  opacity: 0;
  justify-content: space-between;
`
export const SectionLeftBlock = styled.div`
  display: flex;
  width: 25%;
  @media screen and (max-width: 1024px) {
    display: none;
  }
`
export const CenterAlignedBlock = styled.div`
  align-self: center;
`
export const SectionRightBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 60px;
  width: 65%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    gap: 40px;
  }
  @media screen and (max-width: 480px) {
    gap: 30px;
  }
`
export const FooterBlock = styled.div`
  padding: 30px 0;
  @media screen and (max-width: 1024px) {
    padding: 16px 0;
  }
  @media screen and (max-width: 768px) {
    padding: 16px 0 117px;
  }
  @media screen and (max-width: 480px) {
    padding: 16px 0;
  }
`
export const Title = styled.div`
  display: flex;
  max-width: 770px;
  flex-wrap: wrap;
  overflow: hidden;
`
export const Separator = styled.div`
  width: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.12);
  margin: 0 auto;
`