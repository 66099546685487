import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    display: block;
    max-width: 90%;
    max-height: 412px;
    box-sizing: border-box;
    object-fit: contain;
  }

  :hover {
    filter: opacity(.8);
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 20px;
    width: 100%;
    touch-action: none;
    :hover {
      filter: none;
    }
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 0;
  }
`

export const Arrow = styled.div`

`
export const ImagePopup = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: all .3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 100;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  img {
    max-height: 80vh;
    max-width: 90vw;
    transform: translateY(-5%);
  }
`

export const PopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const PopupText = styled.div`
  position: absolute;
  bottom: 40px;
  height: 100px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70vw;
  max-width: 70vw;
  gap: 50px;
  p {
    text-align: center;
  }

  span {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 40px;
    color: rgba(255, 255, 255, 0.5);
    user-select: none;
    :hover {
      filter: opacity(.8);
    }
    :active {
      filter: opacity(.6);
    }
  }
`
