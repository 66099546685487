import styled from "styled-components";
import {PrimaryButtonXl} from "./Button";
import {FormInput, FormLabel} from "./Form";

export const Popup = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-in-out;
`

export const PopupContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 550px;
  padding: 50px 40px;
  box-sizing: border-box;
  @media screen and (max-width: 768px) {
    padding: 30px 20px;
    max-width: 90vw;
  }
`

export const PopupHeader = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }
`

export const PopupBody = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  width: 100%;
`

export const PopupTitle = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 42px;
  line-height: 46px;
  text-transform: uppercase;
  margin: 0;
  margin-bottom: 40px;
  @media screen and (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;
  }
`
export const PopupSubtitle = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  max-width: 90%;
  color: #787878;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const PopupForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  max-width: 100%;
  gap: 60px;
  ${PrimaryButtonXl} {
    width: 100%;
    margin-top: 40px;
    @media screen and (max-width: 768px) {
      margin-top: 40px;
      margin-bottom: 20px;
    }
  }
`
export const PopupInputContainer = styled.div`
  margin-bottom: 10px;
  position: relative;
  span {
    font-size: 11px;
    line-height: 13px;
    color: #ff4828;
    display: none;
    position: absolute;
    right: 0;
    top: 45px;
  }
  .numberInput {
    border: none;
    position: absolute;
    max-width: 100%;
    width: 100%;
    font-family: Golos, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    outline: none;
    z-index: 2;
    background: transparent;
    height: 40px;

    :focus + label {
      font-size: 10px;
      transform: translateY(-20px);

    }
  }
  @media screen and (max-width: 480px) {
    span {
      font-size: 10px;
      line-height: 12px;
      max-width: 85%;
    }
  }
`
