import styled from "styled-components"
import {Content, Section, SectionRightBlock} from "../../styled-components/Base"
import NumberFormat from "react-number-format";

export const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 80px 40px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 80px;
  }
`
export const ContactCell = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 90vw;

  span {
    font-size: 11px;
    line-height: 13px;
    color: #ff4828;
    display: none;
    position: absolute;
    right: 0;
    top: 45px;
  }
  .numberInput {
    border: none;
    position: absolute;
    max-width: 100%;
    width: 100%;
    font-family: Golos, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #1F1F1F;
    outline: none;
    z-index: 2;
    background: transparent;
    height: 40px;

    :focus + label {
      font-size: 10px;
      transform: translateY(-20px);

    }
  }

  @media screen and (max-width: 480px) {
    span {
      font-size: 10px;
      line-height: 12px;
    }
  }
`
export const AddFile = styled.input`
  visibility: hidden;
`
export const AddFileContainer = styled.div`
  display: flex;
  align-items: center;

  input {
    width: 0;
  }
`

export const AddFileLabel = styled.label`
  cursor: pointer;
  margin-right: 15px;
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;


  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }
`

export const ContactSection = styled(Section)`
  padding: 0 0 100px;
  @media screen and (max-width: 1024px) {
    padding: 0 0 60px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 0 40px;
  }
`
export const ContactContent = styled(Content)`
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 40px;
  }
`

export const Contacts = styled.div`

`
export const ContactsBlock = styled.div`
  :first-child {
    padding-bottom: 30px;
  }
`

export const DownloadPresentation = styled.a`
  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }
`
export const ContactLeftBlock = styled.div`

`
export const ContactRightBlock = styled(SectionRightBlock)`
  flex-direction: row;
  padding-left: 20%;
  @media screen and (max-width: 1024px) {
    gap: 30px;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
  @media screen and (max-width: 768px) {
    gap: 30px;
    align-items: start;
    padding-left: 0;
  }
`