import styled from "styled-components"

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1024px) {
    flex-direction: row;
    gap: 30px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
`
export const TabsLeftBlock = styled.div`
  display: flex;
  width: 25%;
  @media screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
    align-items: baseline;
    overflow: scroll;
    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`
export const TabsContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: start;
  }

`
export const Tab = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: #787878;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  :hover {
    filter: opacity(.7);
  }

  :active {
    filter: opacity(.5);
  }

  @media screen and (max-width: 1400px) {
    font-size: 16px;
    line-height: 24px;
  }
`
export const TabActive = styled(Tab)`
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  color: #59B1FF;
  @media screen and (max-width: 1400px) {
    font-size: 16px;
    line-height: 24px;
  }
`

export const TabsVertical = styled(Tabs)`
  flex-direction: column;
  white-space: nowrap;

  ${Tab} {
    margin: 10px 0;

    :first-child {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 1024px) {
    white-space: initial;
    flex-direction: row;
    width: 100%;
    overflow-x: scroll;
    align-items: center;
    ${Tab} {
      margin: 0 20px 0 0;
    }

    ::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
`