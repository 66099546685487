import styled from "styled-components";

export const View = styled.div`
  background-color: #FFFFFF;
  max-width: 1600px;
  padding: 0 100px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    padding: 0 22px;
    max-width: 1400px;
  }
  @media screen and (max-width: 1024px) {
    padding: 0 16px;
    max-width: 1024px;
  }
  @media screen and (max-width: 768px) {
    padding: 0 16px;
    max-width: 768px;
  }
  @media screen and (max-width: 480px) {
    padding: 0 16px;
    max-width: 480px;
  }
`
