import {
    BodySSecondary,
    BodyXLMediumSecondary,
    BodyXLSecondary,
    HeadlineL,
    SubheaderXL
} from "../../styled-components/Font";
import {AboutProductText} from "./text";
import {useEffect, useRef, useState} from "react";
import CountUp from "react-countup";
import {
    CenterAlignedBlock,
    Content,
    Section,
    SectionLeftBlock,
    SectionRightBlock,
    Separator,
    Title
} from "../../styled-components/Base";
import useOnScreen from "../../hooks/useOnScreen";
import {AboutProductGrid, AboutProductGridCell, AboutText} from "./Styled";

export default function AboutProduct() {

    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])

    return <><Separator className={isVisible ? 'animation4' : ''}/>
        <Section className={'about'}>
            <Title>
                <HeadlineL className={isVisible ? 'animation1' : ''}>
                    {AboutProductText.title}
                </HeadlineL>
            </Title>

            <Content ref={ref} className={isVisible ? "animation3" : ''}>
                <SectionLeftBlock>
                    <CenterAlignedBlock>
                        <BodyXLMediumSecondary>
                            {AboutProductText.numbers}
                        </BodyXLMediumSecondary>
                    </CenterAlignedBlock>
                </SectionLeftBlock>
                <SectionRightBlock>
                    <AboutText>
                        <BodyXLSecondary>
                            {AboutProductText.text}
                        </BodyXLSecondary>
                        <BodyXLSecondary>
                            {AboutProductText.text2}
                        </BodyXLSecondary>
                        <BodyXLMediumSecondary>
                            {AboutProductText.numbers}
                        </BodyXLMediumSecondary>
                    </AboutText>
                    <AboutProductGrid>

                        <AboutProductGridCell>
                            {isVisible && <SubheaderXL>
                                <CountUp end={6} duration={2}/>
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                лет успешной работы
                            </BodySSecondary>
                        </AboutProductGridCell>

                        <AboutProductGridCell>
                            {isVisible && <SubheaderXL>
                                <CountUp end={318967} duration={2} separator={' '}/>
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                обслуженных рейсов
                            </BodySSecondary>
                        </AboutProductGridCell>

                        <AboutProductGridCell>
                            {isVisible && <SubheaderXL>
                                <CountUp end={5.1} duration={2} decimals={1}/>&nbsp;млн
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                выполненных задач по обслуживанию воздушных судов&nbsp;и&nbsp;пассажиров
                            </BodySSecondary>
                        </AboutProductGridCell>

                        <AboutProductGridCell ref={ref}>
                            {isVisible && <SubheaderXL>
                                <CountUp end={6} duration={2}/>
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                международных аэропортов
                            </BodySSecondary>
                        </AboutProductGridCell>

                        <AboutProductGridCell>
                            {isVisible && <SubheaderXL>
                                <CountUp end={20.1} duration={2} decimals={1}/>&nbsp;млн
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                обслуженных пассажиров
                            </BodySSecondary>
                        </AboutProductGridCell>

                        <AboutProductGridCell>
                            {isVisible && <SubheaderXL>
                                <CountUp end={6.9} duration={2} decimals={1}/>&nbsp;млн
                            </SubheaderXL>}
                            {!isVisible && <SubheaderXL>0</SubheaderXL>}
                            <BodySSecondary>
                                обработанных багажных мест общим весом&nbsp;152&nbsp;019&nbsp;тонн
                            </BodySSecondary>
                        </AboutProductGridCell>

                    </AboutProductGrid>
                </SectionRightBlock>
            </Content>
        </Section></>
}
