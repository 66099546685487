import {BodyM, HeadlineL, HeadlineL2, LinkLDemibold, SubheaderL} from "../../styled-components/Font";
import ContactForm from "./ContactForm";
import {Logo} from "../../icons/Icons";
import {ContactText} from "./text";
import {Section, Separator, Title} from "../../styled-components/Base";
import {ContactContent, ContactLeftBlock, ContactRightBlock, Contacts, ContactsBlock, ContactSection} from "./Styled";
import useOnScreen from "../../hooks/useOnScreen";
import {useEffect, useRef, useState} from "react";
import {PopupProps} from "../../App";


export default function Contact({onPopupOpen, setPopupText}: PopupProps) {

    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])


    return <><Separator className={isVisible ? 'animation4' : ''}/>
        <Section ref={ref} className={'contact'}>
            <Title>
                <HeadlineL2 className={isVisible ? 'animation1' : ''}>
                    {ContactText.title}
                </HeadlineL2>
                <HeadlineL2 className={isVisible ? 'animation1' : ''}>
                    {ContactText.title1}
                </HeadlineL2>
            </Title>

            <ContactForm setPopupText={(value) => setPopupText && setPopupText(value)} visible={isVisible}/>
        </Section>
        <ContactSection>
            <Separator className={isVisible ? 'animation4' : ''}/>
            <ContactContent className={isVisible ? "animation2" : ''}>
                <ContactLeftBlock>
                    <Logo/>
                </ContactLeftBlock>
                <ContactRightBlock>
                    <Contacts>
                        <ContactsBlock>
                            <SubheaderL>{ContactText.addressTitle}</SubheaderL>
                            <BodyM>{ContactText.address}</BodyM>
                        </ContactsBlock>
                        <SubheaderL>{ContactText.contact}</SubheaderL>
                        <BodyM onClick={() => window.open('tel:' + ContactText.phone)}>
                            {ContactText.phone}
                        </BodyM>
                        <BodyM>
                            {ContactText.email}
                        </BodyM>
                    </Contacts>
                    <LinkLDemibold onClick={() => onPopupOpen()}>
                        Скачать нашу презентацию
                    </LinkLDemibold>
                </ContactRightBlock>
            </ContactContent>
        </ContactSection>
        <Separator className={isVisible ? 'animation4' : ''}/>
    </>
}
