import styled from "styled-components"

export const ClientsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%;
  background: rgba(0, 0, 0, 0.12);
  gap: 1px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
export const ClientsGridCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 270px;
  background: #fff;
  overflow: hidden;
  @media screen and (max-width: 1400px) {
    svg {
      transform: scale(.9);
    }
  }
  @media screen and (max-width: 1024px) {
    height: 200px;
    svg {
      transform: scale(.8);
    }
  }
  @media screen and (max-width: 768px) {
    height: 130px;
    svg {
      transform: scale(.7);
    }
  }
  @media screen and (max-width: 480px) {
    height: 110px;
    svg {
      transform: scale(.6);
    }
  }
`