import styled from "styled-components";

export const Button = styled.button`
  padding: 14px 30px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #FFFFFF;
  border: none;
  text-align: center;
  box-sizing: border-box;
  white-space: nowrap;
  user-select: none;
  width: fit-content;
  height: fit-content;

  :hover {
    background: #1F1F1F;
  }

  :active {
    background: #1F1F1F;
    color: #787878;;
  }
`

export const PrimaryButton = styled(Button)`
  background-color: #59B1FF;
`

export const PrimaryButtonXl = styled(PrimaryButton)`
  padding: 18px 52px;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
export const DisabledPrimaryButtonXl = styled(PrimaryButtonXl)`
  pointer-events: none;
  cursor: default;
  background: #989898;
`