import {BodySSecondary, BodyXL, HeadlineL2} from "../../styled-components/Font";
import {BusinessText} from "./text";
import {ArrowTrending, ChartBar, DateTimeTimer, Finance, Headset, Interface, Supervisor} from "../../icons/Business";
import {Content, Section, SectionLeftBlock, SectionRightBlock, Separator, Title} from "../../styled-components/Base";
import {BusinessCellTitle, BusinessGrid, BusinessGridCell} from "./Styled";
import useOnScreen from "../../hooks/useOnScreen";
import {useEffect, useRef, useState} from "react";

export default function Business() {

    const ref = useRef(null)
    const visible = useOnScreen(ref)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        if (visible) setIsVisible(true)
    }, [visible])

    return <><Separator className={isVisible ? 'animation4' : ''}/>
        <Section className={'business'}>
            <Title>
                <HeadlineL2 className={isVisible ? 'animation1' : ''}>
                    {BusinessText.title1}
                </HeadlineL2>
                <HeadlineL2 className={isVisible ? 'animation1' : ''}>
                    {BusinessText.title2}
                </HeadlineL2>
            </Title>
            <Content className={isVisible ? "animation3" : ''}>
                <SectionLeftBlock/>
                <SectionRightBlock>
                    <BusinessGrid ref={ref}>
                        <BusinessGridCell>
                            <DateTimeTimer/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.dateTimeTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.dateTimeText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <ArrowTrending/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.delayTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.delayText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <ChartBar/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.controlTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.controlText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <Finance/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.moneyTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.moneyText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <Supervisor/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.supervisorTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.supervisorText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <Headset/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.supportTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.supportText}
                            </BodySSecondary>
                        </BusinessGridCell>

                        <BusinessGridCell>
                            <Interface/>
                            <BusinessCellTitle>
                                <BodyXL>
                                    {BusinessText.interfaceTitle}
                                </BodyXL>
                            </BusinessCellTitle>
                            <BodySSecondary>
                                {BusinessText.interfaceText}
                            </BodySSecondary>
                        </BusinessGridCell>
                    </BusinessGrid>
                </SectionRightBlock>
            </Content>
        </Section></>
}
