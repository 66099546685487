import { useEffect } from "react";
import { useState } from "react";
import {FooterBlock} from "../styled-components/Base";
import {CaptionSecondary} from "../styled-components/Font";
import { ToTop } from "../styled-components/Header";
import {scrollTo} from "../utils/scrollTo";

export default function Footer() {

    const datetime = new Date()
    const [toTopVisible, setToTopVisible] = useState(false)
    function setScroll() {
        window.scrollY > 500 ? setToTopVisible(true) : setToTopVisible(false)
    }
    useEffect(() => {
        document.addEventListener("scroll", setScroll);

        return () => document.removeEventListener("scroll", setScroll);
    }, []);

    return <FooterBlock>
        <CaptionSecondary>
            © 2015–{datetime.getFullYear()} DECK.AIR
        </CaptionSecondary>
        <ToTop
            onClick={() => scrollTo(".header")}
            style={toTopVisible ? {visibility: 'visible'} : {}}>
            <span>&#128743;</span> Наверх</ToTop>
    </FooterBlock>
}
